var Login = function () {

  // Function for switching form views (login, reminder and register forms)
  var switchView = function(viewHide, viewShow, viewHash){
    viewHide.slideUp(250);
    viewShow.slideDown(250, function(){
        $('input').placeholder();
    });

    if ( viewHash ) {
        window.location = '#' + viewHash;
    } else {
        window.location = '#';
    }
  };

  return {
    init: function () {
      var formLogin = $('#form-login');
      var formReminder = $('#form-reminder');
      var reminderSuccess = $('#reminder-success');


      $('#link-reminder-login').on('click', function () {
        switchView(formLogin, formReminder, 'reminder');
      });

      $('#link-reminder').on('click', function () {
        switchView(formReminder, formLogin, '');
      });

      if (window.location.hash === '#reminder') {
        formLogin.hide();
        formReminder.show();
      }

      /* Login form validation and submit */
      formLogin.easyForm({
        errorClass: 'has-error',
        wrapperClass: 'form-group',
        tooltipClass: 'help-block animation-slideDown',
        nonFieldErrorClass: 'help-block animation-slideDown',
        googleCaptcha2: true,
        errorPlacement(error, field) {
          field.parents('.form-group > div').append(error);
        },
        success() {
          window.location = '/';
        }
      });

      formReminder.easyForm({
        errorClass: 'has-error',
        wrapperClass: 'form-group',
        tooltipClass: 'help-block animation-slideDown',
        nonFieldErrorClass: 'help-block animation-slideDown',
        googleCaptcha2: true,
        errorPlacement(error, field) {
          field.parents('.form-group > div').append(error);
        },
        success() {
          switchView(formReminder, reminderSuccess, '');
        }
      });
    }
  };
}();
